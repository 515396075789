<mdb-navbar SideClass="navbar navbar-expand-lg navbar-light white fixed-top custom-navbar" [containerInside]="true">
  <mdb-navbar-brand>
    <a class="navbar-brand" (click)="goToProfile()" routerLink="/student/dashboard/user/{{userDetails.username}}">
      <img src="./assets/cg-logo/CareerGraph-Logo.png" style="width:240px;" alt="CareerGraph Logo" class="m-0 mr-4">
    </a>
  </mdb-navbar-brand>
  <links>
    <ul class="navbar-nav mr-auto"></ul>
    <ul class="navbar-nav ml-auto nav-flex-icons">
      <li class="text-center nav-item" style="padding: 0px 10px;">
        <a class="nav-link waves-light pb-0 pt-4" mdbWavesEffect (click)="goToProfile()"
          routerLink="/student/dashboard/user/{{userDetails.username}}" routerLinkActive="cg-active">
          <i class="fad fa-chalkboard fa-lg"></i>
          <br><small>Dashboard</small>
        </a>
      </li>
      <li class="text-center nav-item" style="padding: 0px 10px;">
        <a class="nav-link waves-light pb-0 pt-4" mdbWavesEffect routerLink="/student/allprojects" (click)="goToProfile()"
          routerLinkActive="cg-active">
          <i class="fad fa-pallet fa-lg"></i>
          <br><small>Project</small>
        </a>
      </li>
      <li class="text-center nav-item" style="padding: 0px 10px;">
        <a class="nav-link waves-light pb-0 pt-4" mdbWavesEffect (click)="comingsoon()">
          <i class="fad fa-users-crown fa-lg"></i>
          <br><small>Network Wall</small>
        </a>
      </li>

      <li class="nav-item avatar dropdown" style="padding: 8px;" dropdown>
        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light pl-1 text-center"
          mdbWavesEffect>
          <img *ngIf="userDetails.profile_image" [src]="userDetails.profile_image" [name]="userDetails.fullname"
            class="img-fluid rounded-circle hoverable"
            style="width: 30px !important;height: 30px !important;margin-top: 10px;box-shadow: 0px 1px 12px -5px #000;">
          <img *ngIf="!userDetails.profile_image" [src]="'./assets/images/global/CareerGraph-Avatars/avatar-default.png'"
            [name]="userDetails.fullname" class="img-fluid rounded-circle hoverable"
            style="width: 30px !important;height: 30px !important;margin-top: 10px;box-shadow: 0px 1px 12px -5px #000;">
          <br><small>Me</small>
          <i class="fas fa-sort-down mx-1" style="vertical-align: text-top;"></i>
        </a>
        <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown dropdown-primary" role="menu">
          <a class="dropdown-item waves-light" mdbWavesEffect routerLink="profile-update">My account</a>
          <a class="dropdown-item waves-light" mdbWavesEffect routerLink="support">Support</a>
          <div class="divider dropdown-divider"></div>
          <a class="dropdown-item waves-light" mdbWavesEffect (click)="logout()">Log out</a>
        </div>
      </li>
    </ul>
  </links>
</mdb-navbar>
<div class="container container-shadow post-content-block-adjustment ScrollingBlock" >
  <router-outlet></router-outlet>
</div>