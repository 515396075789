import { User } from './../../_cluster/_modal/user';
import { AuthService } from 'src/app/_cluster/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'underscore';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-post-login-navigation',
  templateUrl: './post-login-navigation.component.html',
  styleUrls: ['./post-login-navigation.component.scss']
})
export class PostLoginNavigationComponent implements OnInit {
  userDetails: User;
  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.authService.currentUser.subscribe((user: User) => {
      this.userDetails = user;
    });
    this.route.params.subscribe(param => {
      console.log('activateRoute', param)
    })
  }
  logout() {
    this.authService.logout();
  }
  goToProfile() {
    this.authService.getUserDetail().subscribe();
    this.getMyData();
  }
  goToWall() {
    // this.getMyData();
    this.router.navigate(['/student/wall/user', this.userDetails.username]);
    this.authService.getUserDetail().subscribe();
  }
  getMyData() {
    this.authService.currentUser.subscribe((user: User) => {
      if (!_.isEmpty(user)) {
        this.userDetails = user;
        // this.router.navigateByUrl('/student/dashboard/user/' + this.userDetails.username);
      }
    });
  }
  comingsoon() {
    Swal.fire({
      title: 'This feature will be coming soon!!!!',
      showClass: {
        popup: 'animated fadeInDown faster'
      },
      hideClass: {
        popup: 'animated fadeOutUp faster'
      }
    });
  }
}
