import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class StudentService {
  Url = `${environment.apiurl}`;
  classmateList: BehaviorSubject<any> = new BehaviorSubject([]);
  friendsDashboardData: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(private http: HttpClient, private ts: ToastrService, private route: Router) { }


  getClassmateList(user_id) {
    this.http.post(this.Url + '/' + 'jain/classmatelist', { user_id }).subscribe((res: any) => {
      if (res && res.status) {
        // console.log('classmatelist', res);
        this.classmateList.next(res);
      }
    });
  }
  getFriendsDashboardData(username) {
    this.http.post(this.Url + '/' + 'user/userdetails', { username }).subscribe((res: any) => {
      if (res && res.status) {
        this.friendsDashboardData.next(res.data[0]);
      }
    });
  }
  shareMyProjectsList(username?, page?, limit?, search_term?: '', filter?: {}) {
    let jData: any = {
      username,
      page,
      limit,
      search_term,
      filter
    };
    return this.http.post(this.Url + '/' + 'jain/shareMyProjectsList', jData);
  }
  updateGroupUserRoles(group_id, to_user_id, role) {
    this.http.post(this.Url + '/' + 'jain/updateGroupUserRoles', { group_id, to_user_id, role }).subscribe((res: any) => {
      if (res && res.status) {
        this.ts.success(res.message);
      } else {
        this.ts.error(res.message);
      }
    });
  }
  supportfeedback(subject, type, message) {
    this.http.post(this.Url + '/' + 'project/supportfeedback', { subject, type, message }).subscribe((res: any) => {
      if (res && res.status) {
        this.ts.success(res.message);
      } else {
        this.ts.error(res.message);
      }
    });
  }
  guestsupportfeedback(name, email, subject, type, message) {
    this.http.post(this.Url + '/' + 'project/guestsupportfeedback', { name, email, subject, type, message }).subscribe((res: any) => {
      if (res && res.status) {
        this.ts.success(res.message);
      } else {
        this.ts.error(res.message);
      }
    });
  }


}
