import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/_cluster/services/auth.service';
import Swal from 'sweetalert2';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-pre-login-navigation',
  templateUrl: './pre-login-navigation.component.html',
  styleUrls: ['./pre-login-navigation.component.scss']
})
export class PreLoginNavigationComponent implements OnInit {

  loginForm: FormGroup;
  error = '';
  loading = false;
  constructor(private formBuilder: FormBuilder, public route: Router, private authService: AuthService) {
  }
  html = '<input type="email" class="form-control mb-4" placeholder="E-mail">';

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }
  get formControls() {
    return this.loginForm.controls;
  }
  onSubmit() {
    if (this.loginForm.invalid) {
      this.error = 'Please enter e-mail and password'
      return;
    }

    this.loading = true;
    this.authService
      .login(this.formControls.username.value, this.formControls.password.value)
      .pipe(first())
      .subscribe(
        data => {
          if (data) {
            this.authService.getUserDetail().subscribe(userData => {
              if (userData[0].user_type === 'student') {
                this.route.navigate(['/student/dashboard/user', userData[0].username]);
              } else if (userData[0].user_type === 'school_admin') {
                this.route.navigate(['/university-dashboard']);
              }
            });
          } else {
            this.error = 'Invalid Credentials';
          }
        },
        error => {
          this.error = error;
          this.loading = false;
        }
      );
  }
  comingsoon() {
    Swal.fire({
      title: 'This feature will be coming soon!!!!',
      showClass: {
        popup: 'animated fadeInDown faster'
      },
      hideClass: {
        popup: 'animated fadeOutUp faster'
      }
    });
  }

}
