import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HTTP_INTERCEPTORS,
    HttpErrorResponse,
    HttpResponse
} from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { finalize, timeout, catchError, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';


@Injectable()
export class HttpsInterceptor implements HttpInterceptor {
    constructor(private mdbSpinningPreloader: MDBSpinningPreloader, private router: Router) { }
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        this.mdbSpinningPreloader.start();
        if (!req.url.includes('jain/studLogin')) {
            const userToken = localStorage.getItem(environment.accessToken);
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${userToken}`,
                    'x-access-token': `${userToken}`,
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': '*',
                    'X-Requested-With': 'XMLHttpRequest',
                    // tslint:disable-next-line: object-literal-key-quotes
                    'enctype': 'multipart/form-data'
                }
            });
        } else {
            req = req.clone({
                setHeaders: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': '*',
                }
            });
        }
        return next.handle(req)
            .pipe(
                timeout(10000),
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = '';
                    if (error.error instanceof ErrorEvent) {
                        // client-side error
                        errorMessage = `Error: ${error.error.message}`;
                    } else {
                        if (error.status === 403) {
                            this.router.navigate(['/login']);
                            localStorage.removeItem(environment.accessToken);
                            // errorMessage = 'Please check your internet connection';
                        } else {
                            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                        }
                    }
                    return throwError(errorMessage);
                }),
                finalize(() => {
                    this.mdbSpinningPreloader.stop();
                    // this.ts.warning('Please check your internet connection');
                }),


                // tap(
                //     event => {
                //         // logging the http response to browser's console in case of a success
                //         if (event instanceof HttpResponse) {
                //             this.mdbSpinningPreloader.stop();
                //             // console.log('api call success :', event);
                //         }
                //     },
                //     error => {
                //         // logging the http response to browser's console in case of a failuer
                //         // tslint:disable-next-line: deprecation
                //         if (event instanceof HttpResponse) {
                //             // tslint:disable-next-line: deprecation
                //             console.log('api call error :', event);
                //             this.ts.warning('Something Went Wrong', 'light');
                //         }
                //     }
                // )
            );

    }
}
export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: HttpsInterceptor, multi: true },

];
