import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { Api } from './api.service';
import { HttpClient } from '@angular/common/http';
import  Swal  from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class AssessmentService {

  Url = `${environment.apiurl}`;
  assessComponentList: BehaviorSubject<any> = new BehaviorSubject([]);
  assessComponentTaskList: BehaviorSubject<any> = new BehaviorSubject([]);
  assessComponentTaskData: BehaviorSubject<any> = new BehaviorSubject([]);
  assessComponentGroupUsers: BehaviorSubject<any> = new BehaviorSubject([]);
  assessComponentTaskMetaData: BehaviorSubject<any> = new BehaviorSubject([]);
  s_selectedComponentId: BehaviorSubject<any> = new BehaviorSubject([]);
  s_selectedTaskId: BehaviorSubject<any> = new BehaviorSubject([]);
  s_selectedCollabrationId: BehaviorSubject<any> = new BehaviorSubject([]);
  bs_ProjectProgress: BehaviorSubject<any> = new BehaviorSubject([]);
  s_SaveComponent = new Subject<string>();

  _projectProgress = this.bs_ProjectProgress.asObservable();
  _currentComponent=this.s_selectedComponentId.asObservable();
  _currentTask=this.s_selectedTaskId.asObservable();
  _currentCollabration=this.s_selectedCollabrationId.asObservable();
  _taskList=this.assessComponentTaskList.asObservable();
  _componentList=this.assessComponentList.asObservable();

  
  constructor(private http: HttpClient, public api: Api) {

   }
  getProjectComponentList(group_id) {
    this.http.post(this.Url + '/project/componentList', { group_id }).subscribe((res: any) => {
      if (res && res.status) {
        console.log('ComponentList');
        this.assessComponentList.next(res['data']);
      } 
    });
  }
  getProjectComponentTask(group_id,component_id) {
    this.http.post(this.Url + '/' + 'jain/component/taskList', { group_id,component_id }).subscribe((res: any) => {
      if (res && res.status) {
         console.log('TaskList');
        this.assessComponentTaskList.next(res['data']);
      } 
    });
  }
  setSelectedComponent(component_id){
    this.s_selectedComponentId.next(component_id);
    
  }
  setSelectedTask(task_id){
    this.s_selectedTaskId.next(task_id);
    
  }
  setSelectedColabration(component_id){
    this.s_selectedCollabrationId.next(component_id);
    
  }
  getProjectProgress(group_id) {
    this.http.post(this.Url + '/project/projectProgress', { group_id }).subscribe((res: any) => {
      if (res && res.status) {
        // console.log('Project Progress');
        this.bs_ProjectProgress.next(res['data']);
      } 
    });
  }
   
  saveProjectComponent( group_id, component_id) {
     this.http.post(this.Url  + '/project/saveComponent', { group_id, component_id}).subscribe((res: any) => {
      if (res && res.status) {
        // console.log(res.message);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: res.message
        });
      }
      this.s_SaveComponent.next(res.status);
      
    });
    return this.s_SaveComponent.asObservable();
  }



}
