import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { User } from '../_modal/user';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})

export class AuthService {
  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public currentUserDashboardSubject: BehaviorSubject<User>;
  public currentUserDashboard: Observable<User>;
  public isLoggedinSubject: BehaviorSubject<boolean>;

  initialUserData = {
    fullname: '',
    email: '',
    user_type: '',
    profile_image: '',
    _id: '',
    first_name: '',
    last_name: '',
    city: '',
    state: '',
    city_id: '',
    state_id: '',
    is_phone_verify: '',
    phone: '',
    about: '',
    year: '',
    course: '',
    school: '',
    username: '',
    gender: '',
    cover_image: '',
    theme_image: '',
    is_friend: 0
  };
  constructor(private http: HttpClient, private route: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(this.initialUserData);
    this.currentUserDashboardSubject = new BehaviorSubject<User>(this.initialUserData);
    this.currentUser = this.currentUserSubject.asObservable();
    this.currentUserDashboard = this.currentUserDashboardSubject.asObservable();
    if (localStorage.getItem(environment.accessToken)) {
      this.isLoggedinSubject = new BehaviorSubject<boolean>(true);
      this.getUserDetail().subscribe();
    } else {
      this.isLoggedinSubject = new BehaviorSubject<boolean>(false);
    }
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  // public get isLoggedin() {
  //   return this.isLoggedinSubject.value;
  // }

  login(email: string, password: string) {

    return this.http
      .post<any>(`${environment.apiurl}/jain/studLogin`, { email, password })
      .pipe(
        map(user => {
          // login successful if there's a jwt token in the response
          if (user && user.status) {
            localStorage.setItem(environment.accessToken, user.data[0].token);
            this.isLoggedinSubject.next(true);
          }
          return user.status;
        })
      );
  }
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(environment.accessToken);
    // document.location.href = 'https://app.careergraph.com';
    localStorage.removeItem('GroupCode');
    // this.currentUserSubject.next(null);
    // this.currentUserDashboardSubject.next(null);
    // this.isLoggedinSubject.next(false);
    this.route.navigateByUrl('/login');
  }
  getUserDetail() {
    return this.http
      .post<any>(`${environment.apiurl}/jain/getDashboardData`, {})
      .pipe(
        map(userdata => {
          if (userdata && userdata.status) {
            this.currentUserSubject.next(userdata.data[0]);
            this.currentUserDashboardSubject.next(userdata.data[0]);
            return userdata.data as User;
          } else {
            this.logout();
          }
        })
      );
  }
}
