<!--Double navigation-->
<header style="user-select: none;">

  <!-- Navbar -->
  <navbar class="assessment-navbar">
    <div class="d-flex flex-row w-100">
      <button mdbBtn color="primary" class="waves-light m-0 rounded-0" type="button" (click)="toggleClass()"
        mdbWavesEffect style="width:104px;">
        <i class="fa fa-2x fa-bars"></i>
      </button>
      <!-- <div class="row" style="width:100%"> -->
      <span class="main-project-heading m-auto d-block d-lg-none" style="font-weight: 400;"> Browser size too
        small</span>
      <div class="main-project-heading d-none d-md-none d-lg-block"
        style="width: 90%;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
        <p class="mx-auto mb-0 collaborative-text-responsive"
          style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;"
          mdbTooltip="{{projectMetaData.project_name}}" placement="right">{{projectMetaData.project_name}}</p>
      </div>
      <div class="main-project-heading mt-0 pr-0" style="width: 20%;text-align: right;">
        <div class="dropdown" mdbDropdown style="display: inline;">
          <button mdbBtn mdbDropdownToggle gradient="blue" class="waves-light ml-0 py-1 px-2" type="button"
            mdbWavesEffect mdbTooltip="Export / Print Project" placement="left">
            <i class="mr-1 fa fa-print"></i>
            <span class="d-none d-sm-none d-md-none d-lg-inline-block project-button-text">Export</span>
          </button>
          <div class="dropdown-menu dropdown-primary">
            <a class="dropdown-item" target="_blank" routerLink="/export/pdf"><i class="fas fa-file-pdf mr-2"></i>Print
              Sections &amp; Tasks</a>
            <!-- <a class="dropdown-item" target="_blank" routerLink="/export/ppt"><i
                class="fas fa-file-powerpoint mr-2"></i>Print Collaborative Slides</a> -->
          </div>
        </div>


        <button mdbBtn color="danger" class="waves-light ml-0 py-1 px-2" type="button" (click)="goTODashBoard()"
          mdbWavesEffect mdbTooltip="Exit Project" placement="left">
          <i class="mr-1 fa fa-sign-in-alt"></i>
          <span class="d-none d-sm-none d-md-none d-lg-inline-block project-button-text">Exit</span>
        </button>
        <a type="button" mdbBtn floating="true" color="slack" size="sm" class="waves-light" mdbWavesEffect
          style="width: 25px; height: 25px;" (click)="basicModal.show()">
          <i class="fas fa-question" style="font-size: 12px !important; line-height: 2;"></i></a>





      </div>
      <!-- </div> -->

    </div>
  </navbar>
  <!-- <div class="assessment-navbar">
    <div class="row" style="width:100%">
      <button mdbBtn color="primary" class="waves-light m-0 rounded-0" type="button" (click)="toggleClass()" mdbWavesEffect style="width:106px">

        <i class="fa fa-2x fa-bars" ></i>
      </button>
      <div class="main-project-heading" style="width: 70%;margin-top:0px">
        <p class="mx-auto" style="margin-top: 5px;margin-bottom: 5px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;width: 100%;">{{projectMetaData.project_name}}</p>
      </div>
      <div class="main-project-heading mt-0" style="width: 8%;">
        <button mdbBtn color="danger" class="waves-light ml-0 py-1 px-2" type="button" (click)="goTODashBoard()" mdbWavesEffect>
          <i class="mr-1 fa fa-sign-in-alt"></i>
          <span class="d-none d-sm-none d-md-none d-lg-inline-block">Exit</span>
        </button>
      </div>
    </div>
  </div> -->
  <!--/. Navbar -->
  <!-- Replace sidebar-collapse with - sidebar-expanded -->
  <sidebar class="assessment-sidebar sidebar-expanded custom-scrollbar-hide" #CollapseExpand>
    <div class="p-3" #togglePadding style="background: #fff; border-bottom: 1px solid #4285f4;">
      <div class="w-75 m-auto company-logo">
        <img [src]="projectMetaData.company_logo" [routerLink]="['/assessment']" routerLinkActive="router-link-active"  class="w-100" [alt]="projectMetaData.company_name"  [mdbPopover]="projectMetaData.company_name" placement="bottom" triggers="hover">
      </div>
    </div>
    <div style="height:100%;">
      <mdb-accordion [multiple]="false" class="section-accordian">
        <mdb-accordion-item  *ngFor="let item of listData let i=index;"
          [collapsed]="activeComponent === item.component_id ? false : true">
          <mdb-accordion-item-head class="accordian-heading-color"
            (click)="item.component_unlock==1 ? gotoComponent(item,i):gotoComponent(item,i);" 
            title="{{item.title}}"
            [style.background-color]=" activeComponent === item.component_id ? '#2253a2' : ''">
            <span class="{{toggleChangeClass}}">
              <i class="mr-2 fas " [ngClass]="item.component_unlock==1 ? 'fa-layer-group' : 'fa-layer-group'"></i>
            </span>
            <span class="font-0 {{toggleChangeClassMargin}}"> S{{item.comp_seq_no}} </span>
            <span class="font-0 {{toggleChangeClass}}"> - Section</span>
          </mdb-accordion-item-head>

          <mdb-accordion-item-body class="accordian-body">
            <!-- <span class="{{selectedTask}}"></span> -->
            <ul class="list-group" *ngIf="taskData.length > 0">

              <li *ngFor="let task of taskData let j=index;" class="list-group-item waves-light font-0"
                [ngClass]="activeTask==task._id?'active':''" (click)="gotoTask(task)" [mdbPopover]="task.title"
                placement="bottom" triggers="hover" style="cursor: pointer;">
                <span mdbWavesEffect>
                  <i class="mr-2 fas fa-tasks-alt {{toggleChangeClass}}"
                    [ngClass]="task.status==1 ? 'green-text' : ''"></i>
                  <i *ngIf="task.status==1" class="mr-1 fas fa-check green-text"></i> T<span
                    class="{{toggleChangeClass}}">ask</span>{{task.task_seq_no}}<span class="{{toggleChangeClass}}"> -
                    {{task.title}}</span>
                </span>
              </li>
              <!-- <li class="list-group-item waves-light font-0"
                [ngClass]="activeColabration==item.component_id?'active':''"
                (click)="item.collabration_unlock==1 ? gotoCollabration(item):collabrationLocked(item)"
                style="padding: 0.5rem !important; cursor: pointer;">
                <span mdbWavesEffect mdbPopover="Collaborate Task" placement="top" triggers="hover">
                  <i *ngIf="item.collabration_unlock===1" class="mr-2 fad fa-code-merge blue-text"></i>
                  <i *ngIf="item.collabration_unlock===0" class="mr-2 fas fa-lock-alt red-text"></i>
                  <span>C-{{item.comp_seq_no}}</span><span class="{{toggleChangeClass}}"> - Collaboration</span>
                </span>
              </li> -->
            </ul>
          </mdb-accordion-item-body>
        </mdb-accordion-item>


      </mdb-accordion>
    </div>


  </sidebar>


</header>
<!--/.Double navigation-->

<!--Main Layout-->

<main class="w-100" style="margin-top: 60px;">
  <div class="container-fluid p-0">
    <div class="{{toggleChangeBlockBG}}" (click)="toggleClass()"></div>
    <router-outlet></router-outlet>
  </div>
</main>
<!--/Main layout-->


<div mdbModal #basicModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header p-0">
        <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal.hide()"
          style="color: #fff; opacity: 1; margin-top: 0px;">
          <span aria-hidden="true">×</span>
        </button>
        <!-- <h4 >Modal title</h4> -->
        <span class="modal-title w-100" id="myModalLabel"
          style="color: #fff; font-weight: 500; font-size: 1.2rem; background-color: #207bbc; padding: 15px;">
          <i class="fas fa-user-headset m-auto" style="font-size:1.5rem"></i> &nbsp;Contact Us
        </span>
      </div>
      <div class="modal-body p-0">
        <div class="container px-5 py-4" style="background: #fbfbfb;">
          <form [formGroup]="supportForm">
            <div class="row">
              <div class="col-12">
                <!-- <mdb-card class="mt-5" style="border: 1px solid #bcbcbc;box-shadow:none"> -->
                <!-- <mdb-card-header style="color: #fff;
                      font-weight: 500;
                      font-size: 1.2rem;background-color: rgb(32, 123, 188);"><i class="fas fa-user-headset m-auto"
                      style="font-size:1.5rem"></i> &nbsp;Contact Us
                    </mdb-card-header> -->

                <!-- <mdb-card-body> -->

                <div class="form-group row">
                  <label class="control-label col-3 text-right my-auto" style="font-size: 0.9rem;">Subject<span
                      class="mandiatory">*</span>:
                  </label>

                  <div class="input-group col-9">

                    <input type="text" class="form-control py-0" placeholder="Subject" mdbInput mdbValidate
                      name="subject" formControlName="subject"
                      style="height:38px;border-radius:0px; font-size: .9rem; font-weight: 300;">
                    <div class="input-group-prepend">
                      <div class="input-group-text py-0 px-3" style="border-left:0px;width: 60px;"><i
                          class="fad fa-at m-auto" style="font-size:1.3rem"></i></div>
                    </div>
                  </div>

                </div>
                <div class="form-group row">
                  <label class="control-label col-3 text-right my-auto" style="font-size: 0.9rem;">Type<span
                      class="mandiatory">*</span>:
                  </label>
                  <div class="input-group col-9 feedback-type">

                    <!-- <ng-select class="form-control p-0" [items]="typeList" placeholder="Feedback-type" mdbInput
                      mdbValidate style="height:38px;border-radius:0px; font-size: 0.9rem;" name="type" required
                      bindLabel="name" bindValue="value" formControlName="type">
                    </ng-select> -->
                    <mdb-select class="form-control p-0" [options]="typeList" mdbInputs 
                      style="height: 38px !important; border-radius:0px; font-size: 0.9rem !important; padding-left: 12px !important;" formControlName="type"
                      [ngClass]="{'text-area-validate': (supportForm.get('type').invalid && (supportForm.get('type').dirty || supportForm.get('type').touched))}"
                      placeholder="Feedback-type"></mdb-select>
                    <div class="input-group-prepend">
                      <div class="input-group-text py-0 px-3" style="border-left:0px;width: 60px;">
                        <i class="fad fa-clipboard m-auto" style="font-size:1.3rem"></i>
                      </div>
                    </div>
                  </div>


                </div>
                <div class="form-group row">
                  <label class="control-label col-3 text-right my-auto" style="font-size: 0.9rem;">Message<span
                      class="mandiatory">*</span>:
                  </label>
                  <div class="input-group col-9">
                    <textarea class="form-control" mdbInput mdbValidate formControlName="message" name="message"
                      style="min-height:40px !important;border-radius:0px; font-size: 0.9rem; font-weight: 300;"
                      placeholder="Message"
                      [ngClass]="{'text-area-validate': (supportForm.get('message').invalid && (supportForm.get('message').dirty || supportForm.get('message').touched))}"></textarea>
                    <div class="input-group-prepend">
                      <div class="input-group-text py-0 px-3" style="border-left:0px;width: 60px;"><i
                          class="fad fa-comment-alt-lines m-auto" style="font-size:1.3rem"></i></div>
                    </div>
                  </div>


                </div>


                <!-- </mdb-card-body>
                  <mdb-card-footer> -->

                <!-- </mdb-card-footer>
                </mdb-card> -->
              </div>
              <!-- <div class="col-5">
                <mdb-card class="m-auto z-depth-0 py-4" style="background-color: transparent !important;">
                 
                  <mdb-card-img src="/./assets/images/global/contact-us.png" alt="Card image cap" class="py-5">
                  </mdb-card-img>

                </mdb-card>
              </div> -->
            </div>
          </form>
        </div>

        <div class="form-group row m-0 p-2" style="background: #f2f2f2; border-top: 1px solid #dadada;">
          <div class="col-6">
            <button type="button" (click)="support();basicModal.hide();" mdbBtn color="success" size="sm" mdbWavesEffect
              class="float-right z-depth-0">Send</button>
          </div>
          <div class="col-6">
            <button type="reset" (click)="supportForm.reset()" mdbBtn color="danger" size="sm" mdbWavesEffect
              class="float-left z-depth-0 text-white">Clear</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>