import { AuthGuard } from 'src/app/_cluster/_auth/auth.guard';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { PreLoginNavigationComponent } from './layouts/pre-login-navigation/pre-login-navigation.component';
import { PostLoginNavigationComponent } from './layouts/post-login-navigation/post-login-navigation.component';
import { AssessmentNavigationComponent } from './layouts/assessment-navigation/assessment-navigation.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/student/dashboard/user',
    pathMatch: 'full'
  },
  {
    path: '',
    component: PreLoginNavigationComponent,
    loadChildren: () => import('./auth/auth.module').then(n => n.AuthModule),
  },
  {
    path: 'student',
    component: PostLoginNavigationComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/students/students.module').then(n => n.StudentsModule),
  },
  {
    path: 'export',
    // component: ,
    loadChildren: () => import('./pages/assessment/exports/exports.module').then(n => n.ExportsModule),
  },
  {
    path: 'assessment',
    canActivate: [AuthGuard],
    component: AssessmentNavigationComponent,
    loadChildren: () => import('./pages/assessment/assessment.module').then(n => n.AssessmentModule),
  },
  {
    path: ':username',
    component: PreLoginNavigationComponent,
    loadChildren: () => import('./pages/user-profile/user-profile.module').then(n => n.UserProfileModule),
  },

  // { path: '**', redirectTo: '/student/dashboard/user', }

];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class AppRoutingModule { }
