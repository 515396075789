<mdb-navbar SideClass="navbar navbar-expand-lg navbar-light white fixed-top scrolling-navbar px-0 pre-login-nav-color custom-navbar">
  <mdb-navbar-brand>
    <a class="navbar-brand" href="/">
      <img src="./assets/cg-logo/CareerGraph-Logo.png" style="width:240px;" alt="CareerGraph Logo" class="m-0 mr-4">
    </a>
  </mdb-navbar-brand>
  <links>
    <!-- Links -->
    <ul class="navbar-nav ml-auto"></ul>
    <!-- Links -->
    <ul class="navbar-nav ml-auto nav-flex-icons">
      <li class="text-center nav-item" style="padding: 0px 15px;margin-top: -10px;">
        <a class="nav-link waves-light pb-0 pt-4" mdbWavesEffect href="https://careergraph.com">
          <i class="fad fa-chalkboard fa-lg"></i>
          <br><small>Home</small>
        </a>
      </li>
      <li class="text-center nav-item" style="padding: 0px 15px;margin-top: -10px;">
        <a class="nav-link waves-light pb-0 pt-4" mdbWavesEffec href="https://careergraph.com/globalprojects">
          <i class="fad fa-pallet fa-lg"></i>
          <br><small>Project</small>
        </a>
      </li>
      <li class="text-center nav-item" style="padding: 0px 15px;margin-top: -10px;">
        <a class="nav-link waves-light pb-0 pt-4" mdbWavesEffec href="https://careergraph.com/internship">
          <i class="fad fa-route-interstate fa-lg"></i>
          <br><small>Internship</small>
        </a>
      </li>
      <li class="text-center nav-item" style="padding: 0px 15px;margin-top: -10px;">
        <a class="nav-link waves-light pb-0 pt-4" mdbWavesEffec href="https://careergraph.com/volunteer">
          <i class="fad fa-users-crown fa-lg"></i>
          <br><small>Volunteer</small>
        </a>
      </li>
      <li class="text-center nav-item" style="padding: 0px 15px;margin-top: -10px;">
        <a class="nav-link waves-light pb-0 pt-4" mdbWavesEffect (click)="comingsoon()">
          <i class="fad fa-home fa-lg"></i>
          <br><small>@Careergraph</small>
        </a>
      </li>
      <li class="text-center nav-item" style="padding: 0px 15px;margin-top: -10px;" *ngIf="!(route.url==='/registration')">
        <a class="nav-link waves-light pb-0 pt-4" mdbWavesEffect routerLink="/registration">
          <i class="fad fa-user-lock" style="vertical-align: text-top;"></i><br><small>Signup</small>
        </a>
      </li>
      <li class="text-center nav-item" style="padding: 0px 15px;margin-top: -10px;" *ngIf="route.url==='/registration'">
        <a class="nav-link waves-light pb-0 pt-4" mdbWavesEffect routerLink="/login">
          <i class="fad fa-user-lock" style="vertical-align: text-top;"></i><br><small>Login</small>
        </a>
      </li>
    </ul>
  </links>
</mdb-navbar>
<div class="pre-content-block-adjustment">
<router-outlet></router-outlet>
</div>