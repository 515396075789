
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { AppComponent } from './app.component';
import { MDBSpinningPreloader, MDBBootstrapModulesPro, ToastModule, TabsModule, NavbarModule, InputsModule, InputUtilitiesModule } from 'ng-uikit-pro-standard';
import { AssessmentNavigationComponent } from './layouts/assessment-navigation/assessment-navigation.component';
import { PreLoginNavigationComponent } from './layouts/pre-login-navigation/pre-login-navigation.component';
import { PostLoginNavigationComponent } from './layouts/post-login-navigation/post-login-navigation.component';
import { AppRoutingModule } from './app-routing.module';
import { httpInterceptorProviders } from './_cluster/interceptors/https.interceptor';
import { Api } from './_cluster/services/api.service';
import { ToastrModule } from 'ngx-toastr';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
// const config: SocketIoConfig = { url: environment.apiurl, options: {transports: [ 'websocket' ]} };
// const config: SocketIoConfig = { url: 'http://localhost:3000' };

@NgModule({
  declarations: [
    AppComponent,
    AssessmentNavigationComponent,
    PreLoginNavigationComponent,
    PostLoginNavigationComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    InputsModule,
    InputUtilitiesModule,
    TabsModule, NavbarModule,
    ToastModule.forRoot(),
    AgmCoreModule.forRoot({
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en#key
      apiKey: 'Your_api_key'
    }),
    AppRoutingModule,
    MDBBootstrapModulesPro.forRoot(),
    // SocketIoModule.forRoot(config),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-center-center',
      preventDuplicates: true,
    })
  ],
  providers: [MDBSpinningPreloader, httpInterceptorProviders, Api],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule { }
